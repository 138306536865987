exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-se-js": () => import("./../../../src/pages/404.se.js" /* webpackChunkName: "component---src-pages-404-se-js" */),
  "component---src-pages-contacts-en-js": () => import("./../../../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-se-js": () => import("./../../../src/pages/contacts.se.js" /* webpackChunkName: "component---src-pages-contacts-se-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-se-js": () => import("./../../../src/pages/index.se.js" /* webpackChunkName: "component---src-pages-index-se-js" */),
  "component---src-pages-models-a-en-js": () => import("./../../../src/pages/models/a.en.js" /* webpackChunkName: "component---src-pages-models-a-en-js" */),
  "component---src-pages-models-a-se-js": () => import("./../../../src/pages/models/a.se.js" /* webpackChunkName: "component---src-pages-models-a-se-js" */),
  "component---src-pages-models-buffering-unit-en-js": () => import("./../../../src/pages/models/buffering-unit.en.js" /* webpackChunkName: "component---src-pages-models-buffering-unit-en-js" */),
  "component---src-pages-models-buffering-unit-se-js": () => import("./../../../src/pages/models/buffering-unit.se.js" /* webpackChunkName: "component---src-pages-models-buffering-unit-se-js" */),
  "component---src-pages-models-c-en-js": () => import("./../../../src/pages/models/c.en.js" /* webpackChunkName: "component---src-pages-models-c-en-js" */),
  "component---src-pages-models-c-se-js": () => import("./../../../src/pages/models/c.se.js" /* webpackChunkName: "component---src-pages-models-c-se-js" */),
  "component---src-pages-models-en-js": () => import("./../../../src/pages/models.en.js" /* webpackChunkName: "component---src-pages-models-en-js" */),
  "component---src-pages-models-se-js": () => import("./../../../src/pages/models.se.js" /* webpackChunkName: "component---src-pages-models-se-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */)
}

